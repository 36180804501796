import React, { FC, useState, useRef, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Keyboard } from '../../components/Keyboard';

import { postReportAndCount } from '../../services/postReportAndCount';

const OyasonoInputReport: FC = () => {
    const inputEl = useRef<HTMLInputElement | null>(null);
    const navigate = useNavigate();
    const [errorCode, setErrorCode] = useState<number>(0);
    const branch_id = "28001";
    const branch_name = "bandaiin";
    const soundFile = '/material/m1.mp3';

    const handleClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
        const target = event.target as HTMLButtonElement;
        if(inputEl.current !== null) {
            if (target.textContent === "×") {
                inputEl.current.value = inputEl.current.value.slice(0, -1);
            } else if (inputEl.current.value.length < 10) {
                inputEl.current.value += target.textContent;
            } else {
                return;
            }
        }
    };

    const handleReport: React.MouseEventHandler<HTMLButtonElement> = async () => {
        if(inputEl.current === null || inputEl.current.value.length !== 10) return
        const report_response = await postReportAndCount(inputEl.current.value, branch_id);
        const audioElement = new Audio(soundFile);
        audioElement.play();

        if(report_response.error?.response?.status === 401) {
            setErrorCode(3);
            return;
        }
        if(report_response.error_code) {
            setErrorCode(1)
            return;
        }
        if(JSON.parse(report_response.data.result).error !== null) {
            setErrorCode(1)
            return;
        }
        if(report_response) {
            console.log({ report_response })
            let countThisYear = JSON.parse(report_response.data.result).info.is_count_this_year_with_branch;
            let countTotal = JSON.parse(report_response.data.result).info.is_count_total_with_branch;
            navigate(`/result`, { state: { count_this_year: countThisYear, count_total: countTotal, branch: branch_name } });
        }
    };

    useEffect(() => {
        if(errorCode !== 0) {
            navigate(`/error`, { state: { branch: branch_name, error_code: errorCode } });
        }
    }, [navigate, errorCode]);

    return (
        <div className="bg-center bg-cover h-screen flex flex-col justify-center items-center">
            <Keyboard handleClick={handleClick} branch={branch_name} handleReport={handleReport} inputEl={inputEl} />
        </div>
    );
};

export default OyasonoInputReport;
